.header {
    font-size: 34px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 26px;
    line-height: 1.3;
    letter-spacing: -0.1px;
    span {
        color: var(--primary-brand-color-600);
    }
}
.pre-name {
    color: var(--gray-light, #717171);
    font-family: var(--rubik);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.description p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 40px;
}
.columns {
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
        margin-bottom: 100px;
    }
    @media screen and (min-width: 980px) {
        gap: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        &.center {
            align-items: center;
        }
        &.start {
            align-items: start;
        }
        &.flex-start {
            align-items: flex-start;
        }
        &.end {
            align-items: end;
        }
        &.flex-end {
            align-items: flex-end;
        }
    }
}
.odd {
    .col-1 {
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (min-width: 980px) {
            text-align: left;
            grid-column: 1 / span 1;
            grid-row: 1;
        }
        @media screen and (min-width: 1308px) {
            grid-column: 1 / span 1;
            grid-row: 1;
            padding-right: 20px;
            max-width: 560px;
        }
    }
    .col-2 {
        text-align: center;
        @media screen and (min-width: 980px) {
            text-align: initial;
            max-width: 560px;
        }
        grid-column: 2;
        grid-row: 1;
        figure {
            margin: 0;
            padding: 0;
            img {
                width: 100%;
                max-width: 560px;
                height: auto;
                margin: 0 auto;
            }
        }
    }
}

.even {
    .col-1 {
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (min-width: 980px) {
            margin-bottom: 0;
            text-align: left;
            grid-column: 2 / span 1;
            grid-row: 1;
        }
        @media screen and (min-width: 1308px) {
            grid-column: 2 / span 1;
            grid-row: 1;
            padding-left: 14px;
            max-width: 560px;
        }
    }
    .col-2 {
        @media screen and (min-width: 980px) {
            max-width: 560px;
        }
        grid-column: 1 / span 1;
        grid-row: 1;
        figure {
            margin: 0;
            padding: 0;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.video-wrapper {
    max-width: 520px;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        margin: 0 auto;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 12px;
    }
}
.video {
    width: 100%;
    padding-top: 56.26%;
    margin: 0 auto;
    position: relative;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}
