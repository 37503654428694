.TwoColumns_header__6RvOB {
    font-size: 34px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 26px;
    line-height: 1.3;
    letter-spacing: -0.1px;
}
    .TwoColumns_header__6RvOB span {
        color: var(--primary-brand-color-600);
    }
.TwoColumns_pre-name__RpNhX {
    color: var(--gray-light, #717171);
    font-family: var(--rubik);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.TwoColumns_description__egm0o p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 40px;
}
.TwoColumns_columns__feez2 {
    display: flex;
    flex-direction: column;
}
.TwoColumns_columns__feez2:not(:last-of-type) {
        margin-bottom: 100px;
    }
@media screen and (min-width: 980px) {
.TwoColumns_columns__feez2 {
        gap: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto
}

        .TwoColumns_columns__feez2.TwoColumns_center__3_vl0 {
            align-items: center;
        }
        .TwoColumns_columns__feez2.TwoColumns_start__5khmn {
            align-items: start;
        }
        .TwoColumns_columns__feez2.TwoColumns_flex-start__aGDCI {
            align-items: flex-start;
        }
        .TwoColumns_columns__feez2.TwoColumns_end__jecQk {
            align-items: end;
        }
        .TwoColumns_columns__feez2.TwoColumns_flex-end__Rz9ZU {
            align-items: flex-end;
        }
    }
.TwoColumns_odd__1KlFW .TwoColumns_col-1__5KzfR {
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
    }
@media screen and (min-width: 980px) {
.TwoColumns_odd__1KlFW .TwoColumns_col-1__5KzfR {
            text-align: left;
            grid-column: 1 / span 1;
            grid-row: 1
    }
        }
@media screen and (min-width: 1308px) {
.TwoColumns_odd__1KlFW .TwoColumns_col-1__5KzfR {
            grid-column: 1 / span 1;
            grid-row: 1;
            padding-right: 20px;
            max-width: 560px
    }
        }
.TwoColumns_odd__1KlFW .TwoColumns_col-2__hfd0G {
        text-align: center;
    }
@media screen and (min-width: 980px) {
.TwoColumns_odd__1KlFW .TwoColumns_col-2__hfd0G {
            text-align: initial;
            max-width: 560px
    }
        }
.TwoColumns_odd__1KlFW .TwoColumns_col-2__hfd0G {
        grid-column: 2;
        grid-row: 1;
}
.TwoColumns_odd__1KlFW .TwoColumns_col-2__hfd0G figure {
            margin: 0;
            padding: 0;
        }
.TwoColumns_odd__1KlFW .TwoColumns_col-2__hfd0G figure img {
                width: 100%;
                max-width: 560px;
                height: auto;
                margin: 0 auto;
            }

.TwoColumns_even__aZS2f .TwoColumns_col-1__5KzfR {
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
    }

@media screen and (min-width: 980px) {

.TwoColumns_even__aZS2f .TwoColumns_col-1__5KzfR {
            margin-bottom: 0;
            text-align: left;
            grid-column: 2 / span 1;
            grid-row: 1
    }
        }

@media screen and (min-width: 1308px) {

.TwoColumns_even__aZS2f .TwoColumns_col-1__5KzfR {
            grid-column: 2 / span 1;
            grid-row: 1;
            padding-left: 14px;
            max-width: 560px
    }
        }

@media screen and (min-width: 980px) {

.TwoColumns_even__aZS2f .TwoColumns_col-2__hfd0G {
            max-width: 560px
    }
        }

.TwoColumns_even__aZS2f .TwoColumns_col-2__hfd0G {
        grid-column: 1 / span 1;
        grid-row: 1;
}

.TwoColumns_even__aZS2f .TwoColumns_col-2__hfd0G figure {
            margin: 0;
            padding: 0;
        }

.TwoColumns_even__aZS2f .TwoColumns_col-2__hfd0G figure img {
                width: 100%;
                height: auto;
            }

.TwoColumns_video-wrapper__sHONC {
    max-width: 520px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {

.TwoColumns_video-wrapper__sHONC {
        margin: 0 auto
}
    }

.TwoColumns_video-wrapper__sHONC iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 12px;
    }
.TwoColumns_video__ErnSQ {
    width: 100%;
    padding-top: 56.26%;
    margin: 0 auto;
    position: relative;
    box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.Button_btn__LpqLK {
    --arrow-height: calc(1em * 0.8);
    --arrow-width: calc(1em * 15 / 13 * 0.8);
    font-family: var(--rubik);
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #e3d500;
    border-radius: 16px;
    padding: 14px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fbe30c;
    color: #191b19;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
}
    .Button_btn__LpqLK.Button_arrow__WYnjb > span {
        padding-right: calc(0.3em + var(--arrow-width));
    }
    .Button_btn__LpqLK:focus:not(:focus-visible) {
        outline: none;
    }
    .Button_btn__LpqLK.Button_arrow__WYnjb::after {
        content: '';
        display: inline-block;
        width: var(--arrow-width);
        height: var(--arrow-height);
        margin-left: calc(-1 * var(--arrow-width));
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        vertical-align: middle;
        transform: translateY(-10%);
    }
    .Button_btn__LpqLK:hover,
    .Button_btn__LpqLK:active,
    .Button_btn__LpqLK:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
    .Button_btn__LpqLK:hover.Button_arrow__WYnjb::after, .Button_btn__LpqLK:active.Button_arrow__WYnjb::after, .Button_btn__LpqLK:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23ffffff"/></svg>');
        }
.Button_large__YDf8n {
    padding: 17px 31px;
    border-radius: 24px;
    font-size: 18px;
    border-width: 3px;
}

.Button_small__3bv9v {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 12px;
    border-width: 2px;
}

.Button_black__lf5bQ {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black__lf5bQ.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_black__lf5bQ:hover,
    .Button_black__lf5bQ:active,
    .Button_black__lf5bQ:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_orange__2u_12 {
    background: #fd733c;
    border-color: #e2612b;
    color: #fff;
}

.Button_orange__2u_12.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_orange__2u_12:hover {
        border-color: #de4d14;
        background-color: #ff5b1a;
    }
.Button_max-orange__aeI2E {
    background: var(--primary-max-brand-color-300, #fc5b1a);
    border-color: var(--primary-max-brand-color-400, #de4d14);
    color: #fff;
}
.Button_max-orange__aeI2E.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
.Button_max-orange__aeI2E:hover,
    .Button_max-orange__aeI2E:active,
    .Button_max-orange__aeI2E:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
    }

.Button_max-black__J3Xt1 {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_max-black__J3Xt1.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-black__J3Xt1:hover,
    .Button_max-black__J3Xt1:active,
    .Button_max-black__J3Xt1:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
    }

.Button_black-white__3uBEk {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black-white__3uBEk.Button_arrow__WYnjb {
        text-wrap: nowrap;
    }

.Button_black-white__3uBEk.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
        }

.Button_black-white__3uBEk:hover,
    .Button_black-white__3uBEk:active,
    .Button_black-white__3uBEk:focus {
        border-color: #000;
        background-color: #fff;
        color: #000;
    }

.Button_black-white__3uBEk:hover.Button_arrow__WYnjb::after, .Button_black-white__3uBEk:active.Button_arrow__WYnjb::after, .Button_black-white__3uBEk:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23000"/></svg>');
        }

.Button_blue__R7zO8 {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue__R7zO8.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue__R7zO8:hover,
    .Button_blue__R7zO8:active,
    .Button_blue__R7zO8:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_blue-yellow__rHe_Q {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue-yellow__rHe_Q.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue-yellow__rHe_Q:hover,
    .Button_blue-yellow__rHe_Q:active,
    .Button_blue-yellow__rHe_Q:focus {
        background-color: #fbe30c;
        border-color: #e3d500;
        color: #191b19;
    }

.Button_blue-yellow__rHe_Q:hover.Button_arrow__WYnjb::after, .Button_blue-yellow__rHe_Q:active.Button_arrow__WYnjb::after, .Button_blue-yellow__rHe_Q:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        }

.Button_ghost__gUr9R {
    border-color: #abbcc9;
    background: transparent;
    color: #000;
}

.Button_ghost__gUr9R:hover,
    .Button_ghost__gUr9R:active,
    .Button_ghost__gUr9R:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }

.Button_ghost-blue__1GUEQ {
    border-color: #19a1ff;
    background: transparent;
    color: #fff;
    transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.Button_ghost-blue__1GUEQ.Button_arrow__WYnjb::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_ghost-blue__1GUEQ:hover,
    .Button_ghost-blue__1GUEQ:active,
    .Button_ghost-blue__1GUEQ:focus {
        background-color: transparent;
        border-color: var(--primary-max-brand-color-300, #fc5b1a);
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }

.Button_ghost-blue__1GUEQ:hover.Button_arrow__WYnjb::after, .Button_ghost-blue__1GUEQ:active.Button_arrow__WYnjb::after, .Button_ghost-blue__1GUEQ:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fc5b1a"/></svg>');
        }
.Button_icon__7soQQ {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3em;
    width: 1em;
    height: 1em;
    background: currentColor;
    -webkit-mask-image: var(--icon-src);
            mask-image: var(--icon-src);
    -webkit-mask-size: contain;
            mask-size: contain;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

.DeskoptBr_br__XGT97 {
    display: none;
}
    @media screen and (min-width: 768px) {.DeskoptBr_br__XGT97 {
        display: unset
}
    }

.Underline_underline__3NP7E {
    --private-padding-right: var(--underline-offset-right, 0);
    --private-padding-left: var(--underline-offset-left, 0.2em);
    --private-underline-color: var(--underline-color, #01a2ff);
    --private-underline-size: var(--underline-size, 0.106em);
    --private-border-bottom: var(--underline-offset-bottom, 0.001em);

    /* --private-underline-size: var(--underline-size, 0.4em); */
    --private-underline-offset: var(--underline-offset, -0.1em);
    text-decoration: none;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    padding: 0 var(--private-padding-right) 0 var(--private-padding-left);
    margin: 0 calc(-1 * var(--private-padding-right)) 0 calc(-1 * var(--private-padding-left));
    background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
        radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
        url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%2301a2ff'/%3E%3C/svg%3E");
    background-position: 0 calc(100.2% + var(--private-border-bottom)), 100% calc(100% - var(--private-underline-size) * 2.35 + var(--private-border-bottom)),
        calc(var(--private-underline-size) * 0.5) calc(100% + var(--private-border-bottom));
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: var(--private-underline-size) var(--private-underline-size), calc(var(--private-underline-size) * 0.5) calc(var(--private-underline-size) * 0.5),
        calc(100% - calc(var(--private-underline-size) * 0.7)) calc(var(--private-underline-size) * 3);
    color: inherit;
    padding-bottom: var(--private-underline-offset);
    margin-bottom: -0.1em;
    margin-left: calc(-1 * var(--private-padding-left));
    margin-right: calc(-1 * var(--private-padding-right));
    border-bottom: var(--private-border-bottom) solid transparent;
}
    .Underline_underline__3NP7E:empty {
        background: none;
    }
    .Underline_underline__3NP7E.Underline_orange__fEPFV {
        --private-underline-color: var(--underline-color, #fd733c);
        background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
            radial-gradient(closest-side, var(--private-underline-color) 0, var(--private-underline-color) 99.99%, transparent 99.99%),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%23fd733c'/%3E%3C/svg%3E");
    }
    .Underline_underline__3NP7E.Underline_max-orange__r8ff_ {
        --private-underline-color: var(--underline-color, #fc5b1a);
        background-image: radial-gradient(closest-side, var(--private-underline-color) 0%, var(--private-underline-color) 99.99%, transparent 99.99%),
            radial-gradient(closest-side, var(--private-underline-color) 0, var(--private-underline-color) 99.99%, transparent 99.99%),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 560.29 47.46' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m560.28 7c-.11 2.41-.02.67 0 3.64-.09 0-.75-.09-1.32-.12-.19 0-.4 0-.63-.02-.41-.01-.89-.03-1.45-.04-.35-.01-.73-.03-1.14-.04-.45 0-.95-.02-1.46-.04-44.93-1.34-345.65-8.25-551.45 36.86-.13.03-.25.04-.36.07-.5.1-1.01.15-1.52.15-.26 0-.53-.01-.79-.04h-.05c-.11-5.83-.11-4.32-.11-8.98 0-3.33 0-1.79 0-4.61 0-3.34.13-2.81.16-2.81.22-.09.47-.09.7-.13 64.3-12 178.29-26.75 363.53-30.19 95.45-1.77 171.29.31 190.94.94.26.01.51.01.74.01.41.01.79.03 1.14.04.56.01 1.04.03 1.45.04.23.01.44.01.63.01.57.03.91.04.99.04-.03 2.39.06 1.13-.03 3.55' fill='%23fc5b1a'/%3E%3C/svg%3E");
    }
    .Underline_underline__3NP7E.Underline_small__DHyV0 {
        --private-padding-right: var(--underline-offset-right, 0);
        --private-padding-left: var(--underline-offset-left, 0);
        --private-underline-size: var(--underline-size, 0.045em);
        background-size: calc(var(--private-underline-size) * 2.2), calc(var(--private-underline-size) * 1.455), calc(100% - calc(var(--private-underline-size) * 1.5)) calc(var(--private-underline-size) * 3);
        background-position: 0 calc(100% + var(--private-border-bottom) * 3), 100% calc(100% - var(--private-underline-size) * 1.31 + var(--private-border-bottom)),
            calc(var(--private-underline-size) * 1) calc(100% + var(--private-border-bottom));
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 892c-246.32 0-446-199.68-446-446s199.68-446 446-446' fill='%23000'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 0c246.32 0 446 199.68 446 446s-199.68 446-446 446' fill='%23000'/%3E%3C/svg%3E"),
            url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 288.68 23.48' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m288.68 23.48h-288.68v-24.36h288.68z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m295.38 6.65c.16-1.87-.92-5.45-6.81-5.68-24.05-.92-237.44-2.7-288.89 5.52-.24.03-.47.08-.7.14-.03 0-.06 0-.09.02-3.33.71-5.91 3.36-6.52 6.71-.84 4.57 3.23 11.59 9.55 9.83 44.88-12.51 247.99-13.61 285.96-11.42 5.12.3 7.34-3.26 7.5-5.12' fill='%23000'/%3E%3C/g%3E%3Cpath d='m0 0h288.68v23.48h-288.68z' fill='none'/%3E%3C/svg%3E");
    }
    .Underline_underline__3NP7E.Underline_small__DHyV0.Underline_max-orange__r8ff_ {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 892c-246.32 0-446-199.68-446-446s199.68-446 446-446' fill='%23fc5b1a'/%3E%3C/svg%3E"),
                url("data:image/svg+xml,%3Csvg viewBox='0 0 892 892' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m446 0c246.32 0 446 199.68 446 446s-199.68 446-446 446' fill='%23fc5b1a'/%3E%3C/svg%3E"),
                url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 288.68 23.48' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m288.68 23.48h-288.68v-24.36h288.68z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m295.38 6.65c.16-1.87-.92-5.45-6.81-5.68-24.05-.92-237.44-2.7-288.89 5.52-.24.03-.47.08-.7.14-.03 0-.06 0-.09.02-3.33.71-5.91 3.36-6.52 6.71-.84 4.57 3.23 11.59 9.55 9.83 44.88-12.51 247.99-13.61 285.96-11.42 5.12.3 7.34-3.26 7.5-5.12' fill='%23fc5b1a'/%3E%3C/g%3E%3Cpath d='m0 0h288.68v23.48h-288.68z' fill='none'/%3E%3C/svg%3E");
        }

.Button_btn__5vn34 {
    --arrow-height: calc(1em * 0.8);
    --arrow-width: calc(1em * 15 / 13 * 0.8);
    font-family: var(--rubik);
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #e3d500;
    border-radius: 16px;
    padding: 14px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fbe30c;
    color: #191b19;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
}
    .Button_btn__5vn34.Button_arrow__t4LpR > span {
        padding-right: calc(0.3em + var(--arrow-width));
    }
    .Button_btn__5vn34:focus:not(:focus-visible) {
        outline: none;
    }
    .Button_btn__5vn34.Button_arrow__t4LpR::after {
        content: '';
        display: inline-block;
        width: var(--arrow-width);
        height: var(--arrow-height);
        margin-left: calc(-1 * var(--arrow-width));
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        vertical-align: middle;
        transform: translateY(-10%);
    }
    .Button_btn__5vn34:hover,
    .Button_btn__5vn34:active,
    .Button_btn__5vn34:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
    .Button_btn__5vn34:hover.Button_arrow__t4LpR::after, .Button_btn__5vn34:active.Button_arrow__t4LpR::after, .Button_btn__5vn34:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23ffffff"/></svg>');
        }
.Button_large__LaZYG {
    padding: 17px 31px;
    border-radius: 24px;
    font-size: 18px;
    border-width: 3px;
}

.Button_small__UR5o6 {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 12px;
    border-width: 2px;
}

.Button_black__vmqnX {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black__vmqnX.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_black__vmqnX:hover,
    .Button_black__vmqnX:active,
    .Button_black__vmqnX:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_red__wh_2R {
    border-color: #002c71;
    background: #f04;
    color: #fff;
}

.Button_red__wh_2R.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_red__wh_2R:hover {
        background: #fff;
        color: #0b3685;
    }

.Button_red__wh_2R:hover.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%230B3685"/></svg>');
        }

.Button_orange__hnCIa {
    background: #fd733c;
    border-color: #e2612b;
    color: #fff;
}

.Button_orange__hnCIa.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_orange__hnCIa:hover {
        border-color: #de4d14;
        background-color: #ff5b1a;
    }

.Button_max-orange__6rifX {
    background: var(--primary-max-brand-color-300, #fc5b1a);
    border-color: var(--primary-max-brand-color-400, #de4d14);
    color: #fff;
}

.Button_max-orange__6rifX.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-orange__6rifX:hover,
    .Button_max-orange__6rifX:active,
    .Button_max-orange__6rifX:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
    }

.Button_max-black__ZKSjr {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_max-black__ZKSjr.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-black__ZKSjr:hover,
    .Button_max-black__ZKSjr:active,
    .Button_max-black__ZKSjr:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
    }

.Button_black-white__ruHE9 {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black-white__ruHE9.Button_arrow__t4LpR {
        text-wrap: nowrap;
    }

.Button_black-white__ruHE9.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
        }

.Button_black-white__ruHE9:hover,
    .Button_black-white__ruHE9:active,
    .Button_black-white__ruHE9:focus {
        border-color: #000;
        background-color: #fff;
        color: #000;
    }

.Button_black-white__ruHE9:hover.Button_arrow__t4LpR::after, .Button_black-white__ruHE9:active.Button_arrow__t4LpR::after, .Button_black-white__ruHE9:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23000"/></svg>');
        }

.Button_blue__bv9Ro {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue__bv9Ro.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue__bv9Ro:hover,
    .Button_blue__bv9Ro:active,
    .Button_blue__bv9Ro:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_ghost__pcGB2 {
    border-color: #abbcc9;
    background: transparent;
    color: #000;
}

.Button_ghost__pcGB2:hover,
    .Button_ghost__pcGB2:active,
    .Button_ghost__pcGB2:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }

.Button_ghost-blue__Kx4XK {
    border-color: #19a1ff;
    background: transparent;
    color: #fff;
    transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.Button_ghost-blue__Kx4XK.Button_arrow__t4LpR::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_ghost-blue__Kx4XK:hover,
    .Button_ghost-blue__Kx4XK:active,
    .Button_ghost-blue__Kx4XK:focus {
        background-color: transparent;
        border-color: var(--primary-max-brand-color-300, #fc5b1a);
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }

.Button_ghost-blue__Kx4XK:hover.Button_arrow__t4LpR::after, .Button_ghost-blue__Kx4XK:active.Button_arrow__t4LpR::after, .Button_ghost-blue__Kx4XK:focus.Button_arrow__t4LpR::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fc5b1a"/></svg>');
        }

.Button_disabled__Puphs {
    border-color: #d3d5d9;
    background: #f5f7fa;
    color: #abbcc9;
}

.Button_disabled__Puphs.Button_arrow__t4LpR::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23abbcc9"/></svg>');
    }

.Button_disabled__Puphs:hover,
    .Button_disabled__Puphs:active,
    .Button_disabled__Puphs:focus {
        border-color: #d3d5d9;
        background: #f5f7fa;
        color: #abbcc9;
    }

